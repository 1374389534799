import ko from 'knockout'
import {basketTranslations, lazyTrans} from 'components/translations'
import {getLocalizedDate} from 'utils/date'

import html from 'components/basket-item.html'

export class BasketItemViewModel {
    constructor (data) {
        this._item = data.item
        this._basket = data.basket
        this._extraClasses = data.extraClasses || ''
        this.isReadOnly = data.readOnly || false

        this.extraClasses = ko.pureComputed(() => {
            let classes = []

            if (this.item()) {
                const type = (this.item().product.productType === 'reactivation' ? this.item().product.productType : this.item().product.type)
                classes.push(`basket-item-${type}`)
            }
            if (this.isAddOn()) {
                classes.push('basket-item-add-on')
            }
            if (this.isToggle()) {
                classes.push('basket-item-with-toggle')
            }

            return classes.join(' ')
        })
        this.basket = ko.pureComputed(() => ko.unwrap(this._basket))
        this.item = ko.pureComputed(() => ko.unwrap(this._item))
        this.addOns = ko.pureComputed(this._getAddOns.bind(this))
        this.isToggle = ko.pureComputed(() => this.item() && this.item().product.defaultQuantity)
        this.isAddOn = ko.pureComputed(() => this.item() && this.item().isAddOn)
        this.isNetworkSeat = ko.pureComputed(() => this.item() && this.item().isNetworkSeat)
        this.isReactivation = ko.pureComputed(() => this.item() && this.item().isReactivation)

        this.productName = ko.pureComputed(() => this.item() && this.item().product.name)
        this.productTypeText = ko.pureComputed(() => this.item() && this.item().product.typeName)
        this.productDescription = ko.pureComputed(this._getProductDescription.bind(this))
        this.paymentPeriodText = ko.pureComputed(() => {
            if (!this.item() || !this.item().product.isPeriodic) {
                return null
            }

            return this.item().product.isMonthly
                ? lazyTrans(basketTranslations.monthPeriodMsg) : lazyTrans(basketTranslations.yearPeriodMsg)
        })
        this.quantity = ko.pureComputed({
            read: () => this.item().quantity,
            write: value => this._setQuantity(value)
        })
        this.checked = ko.pureComputed({
            read: () => this.item() && this.item().quantity > 0,
            write: value => this._setQuantity(value ? this.item().product.defaultQuantity || 1 : 0)
        })
        this.shouldShowPrice = ko.pureComputed(this._shouldShowPrice.bind(this))
        this.isFree = ko.pureComputed(this._isFree.bind(this))
        this.isUnavailable = ko.pureComputed(() => this.item() && !this.item().isAvailable)

        this.canChangeQuantity = ko.pureComputed(() => {
            return !this.isToggle() && this.item().product.isEditable &&
                (!this.item().product.maximumQuantity || this.item().product.maximumQuantity > 1)
        })
        this.canRemove = ko.pureComputed(() => {
            return !this.isToggle() && this.item().product.isEditable && !this.isReadOnly
        })
        this.distributorPrefix = ko.pureComputed(() => this.item() && this.item().distributorPrefix)
        this.schedule = ko.pureComputed(() => this.item().schedule.map(s => new ScheduleViewModel(this, s)))
    }

    _getAddOns () {
        if (!this.item()) {
            return []
        }

        // todo: do we need to filter add-ons?
        // co-term add-ons are so far the only ones to handle on the item level
        return this.item().addOns.filter(addOn => {
            return addOn.product.addOnCode === 'co-term' && addOn.quantity > 0
        })
    }

    _getProductDescription () {
        if (this.item()) {
            if (this.item().product.type === 'license') {
                if (this.item().product.isReactivation) {
                    return lazyTrans(basketTranslations.reactivationDescription, {
                        licenseId: `<span class="no-wrap">${this.item().product.data.license_id || ''}</span>`
                    })
                }
                // licenses are in specific groups now so no need to double that info
                return ''
            } else if (this.item().product.type === 'training') {
                return this.item().product.description
            } else if (this.item().product.type === 'co-term-existing') {
                return lazyTrans(basketTranslations.coTermDescription, {
                    days: this.item().product.data.proration_days,
                    date: getLocalizedDate(this.item().product.data.co_term_date)
                })
            }
        }

        return this.productTypeText()
    }

    _shouldShowPrice () {
        return this.item() && this.item().quantity > 0
    }

    _isFree () {
        // currently we do not have "free" products and we do not want to show this string
        return this.shouldShowPrice() && !this.item().hasDiscount && Number(this.item().priceNoTax) === 0 && false
    }

    remove () {
        this._setQuantity(0)
    }

    increaseQuantity () {
        this._setQuantity(Number(this.quantity()) + 1)
    }

    decreaseQuantity () {
        this._setQuantity(Number(this.quantity()) - 1)
    }

    _setQuantity (value) {
        this.basket().setQuantity(this.item(), Number(value))
    }
}

class ScheduleViewModel {
    constructor (item, data) {
        this.item = item
        this.basketLine = item.item
        this.startDate = ko.observable(data.start_date)
        this.priceExclTax = ko.observable(data.price_excl_tax)
        this.priceInclTax = ko.observable(data.price_incl_tax)
        this.discountedPriceExclTax = ko.observable(data.discounted_price_excl_tax)
        this.discountedPriceInclTax = ko.observable(data.discounted_price_incl_tax)
        this.unitPriceExclTax = ko.observable(data.unit_price_excl_tax)
        this.unitPriceInclTax = ko.observable(data.unit_price_incl_tax)
        this.discountedUnitPriceExclTax = ko.observable(data.discounted_unit_price_excl_tax)
        this.discountedUnitPriceInclTax = ko.observable(data.discounted_unit_price_incl_tax)
        this.period = ko.observable(data.period)
        this.quantity = ko.observable(data.quantity)

        this.fullPrice = ko.computed(() => {
            return this.basketLine().displayWithTax ? this.unitPriceInclTax() : this.unitPriceExclTax()
        })

        this.finalPrice = ko.computed(() => {
            return this.basketLine().displayWithTax ? this.discountedUnitPriceInclTax() : this.discountedUnitPriceExclTax()
        })
    }
}

ko.components.register('VW.Components.BasketItem', {
    viewModel: BasketItemViewModel,
    template: html
})
